import React from 'react';
import styled from 'styled-components'
import dayjs from 'dayjs'
import { formatHour } from './Utils.js';

const Wrapper = styled.div`
display: flex;
align-items: flex-start;
justify-content: space-between;
padding-bottom: 16px;

@media only screen and (max-width: 320px) {
  font-size: .8em;
}
`
const ScheduleDay = styled.div`
    text-align: left;
    width: 49%;
`
const DayOfWeek = styled.span`
    font-weight: bold
`

const MonthAndDay = styled.span`
    font-weight: normal
`

const ScheduleTime = styled.div`
    text-align: right;
    width: 49%;
`

const ScheduleTimeOpen = styled.span`
    font-weight: normal
`

const ScheduleTimeClose = styled.span`
    font-weight: normal
`


function getScheduleDay (date) {
    const dateObj = dayjs(date);
    const dayOfWeek = dateObj.format('dddd');
    const month = dateObj.format('M');
    const monthDay = dateObj.format('D');

    return (
        <ScheduleDay>
            <DayOfWeek>{`${dayOfWeek} `}</DayOfWeek>
            <MonthAndDay>{`(${month}/${monthDay})`}</MonthAndDay>
        </ScheduleDay>
    )

}

function getScheduleTime (open, close) {
    return (
        <ScheduleTime>
            <ScheduleTimeOpen>{ formatHour(open) }</ScheduleTimeOpen> - <ScheduleTimeClose>{ formatHour(close)  }</ScheduleTimeClose>
        </ScheduleTime>
    )
}

const StoreScheduleItem = (props) => {

  return (
    <Wrapper>
        { getScheduleDay(props.date) }
        { getScheduleTime(props.open, props.close) }
    </Wrapper>
  );
}

export default StoreScheduleItem
