import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import Title from '../components/Title'
import Description from '../components/Description'
import HeroImage from '../components/HeroImage'

import hero from '../assets/notfound.png'

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    max-width: 650px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`
const NotFound = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <HeroImage src={hero} alt='' />
            <Title text={t('notfound.text.header', { defaultValue: 'Order Not Found'})} />
            <Description text={t('notfound.text.cantfind', { defaultValue: 'Sorry! We can\'t find your order.'})} />
            <Description text={t('notfound.text.already.pickedup', { defaultValue: 'Perhaps you already picked up this one?'})}  />
        </Container>
    )
}

export default NotFound