import React from 'react';
import styled from 'styled-components';

import Title from '../components/Title';
import Description from '../components/Description';
import Header from "../components/Header";

const Wrapper = styled.div`
width: 100%;
margin: 0 auto;
max-width: 650px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`

const Spinner = styled.div`
  margin: 200px auto 95px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  p{
    text-align: center;
  }
`
// NOTE: We cannot use language translations in this component as this is the fallback component while languages are loading
const Bootstrap = (props) => {
    return (
        <Wrapper>
            <Header />
            <Spinner/>
            <Title text="Initialization..."/>
            <Description text="Please wait"/>
        </Wrapper>
    )
}
export default Bootstrap
