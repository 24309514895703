import React, {useContext} from 'react';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import LinkButton from '../components/LinkButton'
import Title from '../components/Title'
import Text from '../components/Text'
import StoreInfo from '../components/StoreInfo'
import OrderInfo from '../components/OrderInfo'
import useOrderCheck from '../hooks/useOrderCheck'
import {ModalContext} from "../context/ModalContext";
import {AppContext} from "../context/AppContext";
import ErrorDetails from "../components/ErrorDetails";

const Container = styled.div`
    padding: 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }`

const OnMyWay = (props) => {
    const { t } = useTranslation();
    const { value: appState } = useContext(AppContext);
    const { setValue: setModalState } = useContext( ModalContext );
    useOrderCheck();

    if(appState?.hasError){
        return <ErrorDetails />
    } else {
        return (
            <Container>
                <Title text={t('onway.text.header', { defaultValue: 'Your curbside order is ready!'})} />
                <Text text={t('onway.text.let_us_know', { defaultValue: 'Please let us know when you are on your way then enter your vehicle details.'})} />
                <Text text={t('onway.text.bring_id', { defaultValue: 'Reminder, please bring your ID when picking up your order.'})} />
                <StoreInfo />
                <OrderInfo />
                <LinkButton
                    text={t('onway.button.primary', { defaultValue: `I'm On My Way` })}
                    fn={()=> setModalState(state => ({
                        ...state,
                        showOverlay: true,
                        showVehicleDetailsModal: true
                    }))}
                />
            </Container>
        )
    }

}

export default OnMyWay