import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import {Helmet} from 'react-helmet';

import Header from './components/Header';
import Loading from './views/Loading';
import OnMyWay from './views/OnMyWay';
import ImHere from './views/ImHere';
import Success from './views/Success';
import VerifyCode from './views/VerifyCode';
import NotFound from './views/NotFound';
import ErrorBoundary from './components/ErrorBoundary';

import {ThemeContextProvider} from './context/ThemeContext';
import {AppContext, AppContextProvider} from './context/AppContext';
import {ModalContextProvider} from './context/ModalContext';

import ViewContainer from "./components/ViewContainer";

function App() {

    return (
        <Router>
            <Helmet>
                <meta property="og:image"
                      content="https://www.rlmedia.io/is/image/PoloGSI/s7-1356778_alternate10?$rl_df_pdp_5_7_a10$"/>
            </Helmet>

            <AppContextProvider>
            <ThemeContextProvider brand={sessionStorage.getItem('brand')}>
                <ModalContextProvider>
                    <ViewContainer>
                        <Header brand={sessionStorage.getItem('brand')}/>
                        <ErrorBoundary>
                            <Switch>
                                    {/* Default path */}
                                    <Route exact path="/" render={() => (
                                        <Redirect to="/404"/>
                                    )}/>

                                    {/* Not found view */}
                                    <Route exact path="/404">
                                        <NotFound/>
                                    </Route>

                                    <Route path="/order/:id">
                                        <Loading to='onmyway'/>
                                    </Route>

                                    <Route exact path="/onmyway">
                                        <OnMyWay order={AppContext.order}/>
                                    </Route>

                                    <Route exact path="/imhere">
                                        <ImHere/>
                                    </Route>

                                    <Route exact path="/verifycode">
                                        <VerifyCode/>
                                    </Route>

                                    <Route exact path="/success">
                                        <Success/>
                                    </Route>

                                    <Route path="*">
                                        <NotFound/>
                                    </Route>

                                </Switch>
                        </ErrorBoundary>
                    </ViewContainer>
                </ModalContextProvider>
            </ThemeContextProvider>
            </AppContextProvider>

        </Router>
    );
}

export default App;
