import axios from 'axios';
import vars from '../config/vars';
import * as Sentry from "@sentry/browser";

const apiRequest = async (options) => {
  return await axios.post(
      vars.curbsideApi,
      options.data,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        params: {
          method: options.data.method
        }
      }
  ).catch(err => {
    return handleError(err, options);
  })
};

const handleError = (err, options) => {
  let message = 'There was an error processing your request.';
  let data = null;

  if (err.response) {
    message = err.response.data.message;
    data = err.response.data;
  } else if (err.request) {
    // client never received a response, or request never left
    message = 'There was an error processing your request. No error message was received';
    data = err.request;
  } else {
    message = 'There was an error processing your request. Unfortunately, no error information could be retrieved.';
  }

  const sentryError = new Error();
  sentryError.name = `Curbside API Error calling ${options.data.method}`;
  sentryError.message = message;

  Sentry.withScope(function(scope) {
    scope.setTag("apiMethod", options.data.method);
    scope.setLevel("Error");
    scope.setExtra("apiMessage", message);
    scope.setExtra("apiResponseData", data);
    scope.setExtra("apiRequestData", options.data);
    Sentry.captureException(sentryError);
  });
  throw err;
}

async function getOrderSummary(id, orderKey, brand) {
  let options = {};
  options.data = {
    method: "getOrderSummary",
    brand,
    order: {
      orderNumber: id,
      orderKey: orderKey
    }
  };

  try {
    const resp = await apiRequest(options);
    const order = resp.data.result;
    return order ? order : null;
  }
  catch(err) {
    throw err;
  }
}

async function startPickup(payload) {
  let options = {};
  options.data = {
    method: "startPickup",
    ...payload
  };

  try {
    const resp = await apiRequest(options);
    const order = resp.data.result;
    return order ? order : null;
  }
  catch(err) {
    throw err;
  }
}

async function updatePickupInfo(payload) {
  let options = {};
  options.data = {
    method: "updatePickupInfo",
    ...payload
  };

  try {
    const resp = await apiRequest(options);
    const order = resp.data.result;
    return order ? order : null;
  }
  catch(err) {
    throw err;
  }
}

async function customerArrived(payload) {
  let options = {};
  options.data = {
    method: "arrived",
    ...payload
  };

  try {
    const resp = await apiRequest(options);
    const order = resp.data.result;
    return order ? order : null;
  }
  catch(err) {
    throw err;
  }
}

async function customerCancelled(payload) {
  let options = {};
  options.data = {
    method: "cancelPickup",
    ...payload
  };

  try {
    const resp = await apiRequest(options);
    const order = resp.data.result;
    return order ? order : null;
  }
  catch(err) {
    throw err;
  }
}

export {
  getOrderSummary,
  startPickup,
  updatePickupInfo,
  customerArrived,
  customerCancelled
}