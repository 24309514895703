// Use this log for genertic Concierge fallback
//import logo from '../assets/logos/logo.png'
import jrdLogo from '../assets/logos/jrd.png'
import kaoLogo from '../assets/logos/kao.png'
import kayLogo from '../assets/logos/kay.png'
import pagLogo from '../assets/logos/pag.png'
import zalLogo from '../assets/logos/zal.png'
import zaoLogo from '../assets/logos/zao.png'
import peoLogo from '../assets/logos/peo.png'
import conciergeLogo from '../assets/logos/logo.png'

export const BRANDS = {
    JRD: {
        logo: jrdLogo,
        primary: '#000000'
    },
    KAY: {
        logo: kayLogo,
        primary: '#3E0E3A'
    },
    KAO: {
        logo: kaoLogo,
        primary: '#B4707F'
    },
    PAG: {
        logo: pagLogo,
        primary: '#000000'
    },
    ZAO: {
        logo: zaoLogo,
        primary: '#5291C3'
    },
    ZAL: {
        logo: zalLogo,
        primary: '#6737D4'
    },
    PEO: {
        logo: peoLogo,
        primary: '#6C1D45'
    },
    DEFAULT: {
        logo: conciergeLogo,
        primary: '#072543'
    }
}
