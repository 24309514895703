class Backend {
    constructor(services, backendOptions = {}, i18nextOptions = {}){
        this.init(services, backendOptions, i18nextOptions);
    }

    init(services, backendOptions, i18nextOptions){
        // Validate backendOptions
        this.brand = backendOptions?.brand || '';
    }

    removeEmptyOrNullKeys = (obj) => {
        Object.keys(obj).forEach(k =>
            (obj[k] && typeof obj[k] === 'object') && this.removeEmptyOrNullKeys(obj[k]) ||
            (!obj[k] && obj[k] !== undefined) && delete obj[k]
        );
        return obj;
    };

    async read(language, namespace, callback) {
        /* return resources */

        console.log(`Custom Backend called with lang: ${language} and namespace: ${namespace} and brand ${this.brand.toLowerCase()}`);

        // Dynamically import language file (using detected browser preferred lang)
        let langFile = null;
        try {
            const langFileImport = await import(`./assets/locales/${language}/${namespace}.json`);
            langFile = langFileImport.default
            console.log(`language file "./assets/locales/${language}/${namespace}.json" successfully imported:`,langFile)
        }
        catch (e) {
            console.log(`Error importing language file: ./assets/locales/${language}/main.json`)
            callback(true, null);
        }

        // Dynamically import override language file
        let brandOverride = null;
        try {
            if(language !== 'dev' && this.brand.length){
                const brandOverrideImport = await import(`./assets/locales/${language}/main.${this.brand.toLowerCase()}.json`);
                brandOverride = this.removeEmptyOrNullKeys(brandOverrideImport.default)
                console.log('overridden file import:', brandOverride)
            }
        }
        catch (e) {
            console.log(`Error importing file: ./assets/locales/${language}/main.${this.brand.toLowerCase()}.json`)
            callback(true, null);
        }

        const finalLangFile = Object.assign({}, langFile, brandOverride);
        callback(null, finalLangFile);
    }

}
Backend.type = "backend";

export default Backend;